@import '../../styles/mixins';

.common-error-page {
  box-sizing: border-box;
  margin: 16px 24px;
  padding: 21px 24px;
  background: #ffffffcc 0% 0% no-repeat padding-box;
  box-shadow: 6px 6px 16px #00000080;
  border: 1px solid #979797;
  border-radius: 3px;
  .result-panel {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 4px #00000080;
    border: 1px solid #E4E5E6;
    border-radius: 3px;
    padding: 40px 35%;
  }
}
