@import '../../styles/mixins';
.order-detail-table-row {
    td {
        vertical-align: top;
    }
}
.order-detail {
    margin-left: 20px;
    margin-right: 20px; 
}

