:root {
  --green-color: #56B146;
  --blue-color: #008CD2;
}
/* MyriadPro-Regular */
@font-face {
  font-family: 'MyriadPro';
  font-style: normal;
  src: url(/static/media/MyriadPro-Regular.59cf8cfe.woff) format('woff'),  url(/static/media/MyriadPro-Regular.a89cf050.otf) format('opentype');
}
/* MyriadPro-Bold */
@font-face {
  font-family: 'MyriadPro-Bold';
  font-style: normal;
  src: url(/static/media/MyriadPro-Bold.eeca4759.woff) format('woff'),  url(/static/media/MyriadPro-Bold.9c12cd3b.otf) format('opentype');
}
/* MyriadPro-Semibold */
@font-face {
  font-family: 'MyriadPro-Semibold';
  font-style: normal;
  src: url(/static/media/MyriadPro-Semibold.4d63236c.otf) format('opentype');
}
body {
  margin: 0;
  padding: 0;
  font-family: MyriadPro, sans-serif;
  background: transparent url(/static/media/sysco-bg.1c30c24d.png) 0% 0% no-repeat fixed padding-box;
  background-size: cover;
}
.text-color-primary {
  color: #008CD2;
}
.ant-btn-green {
  background: #56B146 0% 0% no-repeat padding-box;
  background: var(--green-color) 0% 0% no-repeat padding-box;
  border: 1px solid #56B146;
  border: 1px solid var(--green-color);
  border-radius: 3px;
  color: #ffffff;
  /* MyriadPro-Regular */
  /* MyriadPro-Bold */
  /* MyriadPro-Semibold */
}
.ant-btn-green :root {
  --green-color: #56B146;
  --blue-color: #008CD2;
}
@font-face {
  font-family: 'MyriadPro';
  font-style: normal;
  src: url(/static/media/MyriadPro-Regular.59cf8cfe.woff) format('woff'),  url(/static/media/MyriadPro-Regular.a89cf050.otf) format('opentype');
}
@font-face {
  font-family: 'MyriadPro-Bold';
  font-style: normal;
  src: url(/static/media/MyriadPro-Bold.eeca4759.woff) format('woff'),  url(/static/media/MyriadPro-Bold.9c12cd3b.otf) format('opentype');
}
@font-face {
  font-family: 'MyriadPro-Semibold';
  font-style: normal;
  src: url(/static/media/MyriadPro-Semibold.4d63236c.otf) format('opentype');
}
.ant-btn-green body {
  margin: 0;
  padding: 0;
  font-family: MyriadPro, sans-serif;
  background: transparent url(/static/media/sysco-bg.1c30c24d.png) 0% 0% no-repeat fixed padding-box;
  background-size: cover;
}
.ant-btn-green .text-color-primary {
  color: #008CD2;
}
.ant-btn-green .ant-btn-green {
  background: #56B146 0% 0% no-repeat padding-box;
  background: var(--green-color) 0% 0% no-repeat padding-box;
  border: 1px solid #56B146;
  border: 1px solid var(--green-color);
  border-radius: 3px;
  color: #ffffff;
}
.ant-btn-green .ant-btn-green:hover,
.ant-btn-green .ant-btn-green:focus {
  background-color: #56B146c9;
  border: 1px solid #56B146;
  border: 1px solid var(--green-color);
  color: #ffffff;
}
.ant-btn-green .ant-btn-green-border {
  border: 1px solid #56B146;
  border: 1px solid var(--green-color);
  border-radius: 3px;
  color: #56B146;
  color: var(--green-color);
}
.ant-btn-green .ant-btn-green-border:hover,
.ant-btn-green .ant-btn-green-border:focus {
  border: 2px solid #56B146;
  border: 2px solid var(--green-color);
  color: #56B146;
  color: var(--green-color);
}
.ant-btn-green .ant-btn-blue-border {
  border: 1px solid #008CD2;
  border: 1px solid var(--blue-color);
  border-radius: 3px;
  color: #008CD2;
  color: var(--blue-color);
}
.ant-btn-green .ant-btn-blue-border:hover,
.ant-btn-green .ant-btn-blue-border:focus {
  border: 2px solid #008CD2;
  border: 2px solid var(--blue-color);
  color: #008CD2;
  color: var(--blue-color);
}
.ant-btn-green .display-flex {
  display: -webkit-flex;
  display: flex;
}
.ant-btn-green .align-items-center {
  -webkit-align-items: center;
          align-items: center;
}
.ant-btn-green .font-size-14 {
  font: 14px/21px MyriadPro, sans-serif;
}
.ant-btn-green .font-size-16 {
  font: 500 16px/18px MyriadPro, sans-serif;
}
.ant-btn-green .font-size-18 {
  font: 500 18px/23px MyriadPro, sans-serif;
}
.ant-btn-green .font-size-24 {
  font: 24px/29px MyriadPro-Bold, sans-serif;
}
.ant-btn-green .font-size-28 {
  font: 500 28px/34px MyriadPro, sans-serif;
}
.ant-btn-green .ant-form-item-label > label:not(.ant-form-item-required) {
  margin-left: 14px;
}
.ant-btn-green:hover,
.ant-btn-green:focus {
  background-color: #56B146c9;
  border: 1px solid #56B146;
  border: 1px solid var(--green-color);
  color: #ffffff;
}
.ant-btn-green-border {
  border: 1px solid #56B146;
  border: 1px solid var(--green-color);
  border-radius: 3px;
  color: #56B146;
  color: var(--green-color);
}
.ant-btn-green-border:hover,
.ant-btn-green-border:focus {
  border: 2px solid #56B146;
  border: 2px solid var(--green-color);
  color: #56B146;
  color: var(--green-color);
}
.ant-btn-blue-border {
  border: 1px solid #008CD2;
  border: 1px solid var(--blue-color);
  border-radius: 3px;
  color: #008CD2;
  color: var(--blue-color);
}
.ant-btn-blue-border:hover,
.ant-btn-blue-border:focus {
  border: 2px solid #008CD2;
  border: 2px solid var(--blue-color);
  color: #008CD2;
  color: var(--blue-color);
}
.display-flex {
  display: -webkit-flex;
  display: flex;
}
.align-items-center {
  -webkit-align-items: center;
          align-items: center;
}
.font-size-14 {
  font: 14px/21px MyriadPro, sans-serif;
}
.font-size-16 {
  font: 500 16px/18px MyriadPro, sans-serif;
}
.font-size-18 {
  font: 500 18px/23px MyriadPro, sans-serif;
}
.font-size-24 {
  font: 24px/29px MyriadPro-Bold, sans-serif;
}
.font-size-28 {
  font: 500 28px/34px MyriadPro, sans-serif;
}
.ant-form-item-label > label:not(.ant-form-item-required) {
  margin-left: 14px;
}
.head-panel {
  box-sizing: border-box;
  top: 0;
  left: 0;
  margin: 0;
  padding: 16px 24px;
  width: 100%;
  height: 70px;
  background-color: #008CD2;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  color: #ffffff;
}
.head-panel .header-left {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.head-panel .header-left .sysco-logo {
  width: 107px;
  height: 36px;
  padding-right: 17px;
}
.head-panel .header-left .custom-divider {
  height: 4em;
  font-size: 10px;
  border-color: #ffffffd4;
  border-width: 2px;
  margin: 0 30px 0 20px;
}
.home-menu {
  box-sizing: border-box;
  width: 100%;
  height: 35px;
  background-color: #A1A1A1;
  box-shadow: 0px 3px 5px #00000029;
  padding: 0px 26px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: #ffffff;
  margin-bottom: 0px;
}
.home-menu .menu-link {
  color: #ffffff;
  padding: 0px 6px;
}
.home-menu .menu-link:hover,
.home-menu .menu-link:visited,
.home-menu .menu-link:active {
  text-decoration: underline;
}
.common-error-page {
  box-sizing: border-box;
  margin: 16px 24px;
  padding: 21px 24px;
  background: #ffffffcc 0% 0% no-repeat padding-box;
  box-shadow: 6px 6px 16px #00000080;
  border: 1px solid #979797;
  border-radius: 3px;
}
.common-error-page .result-panel {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #00000080;
  border: 1px solid #E4E5E6;
  border-radius: 3px;
  padding: 40px 35%;
}
.place-order .place-order-panel {
  box-sizing: border-box;
  margin: 16px 24px;
  padding: 21px 24px;
  background: #ffffffcc 0% 0% no-repeat padding-box;
  box-shadow: 6px 6px 16px #00000080;
  border: 1px solid #979797;
  border-radius: 3px;
}
.place-order .place-order-panel .order-header-panel,
.place-order .place-order-panel .order-detail-panel {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #00000080;
  border: 1px solid #e4e5e6;
  border-radius: 3px;
}
.place-order .place-order-panel .order-header-panel {
  padding: 20px 0px 0px 0px;
  min-height: 400px;
  position: relative;
}
.place-order .place-order-panel .order-detail-panel {
  margin-top: 12px;
}
.place-order .place-order-panel .order-detail-panel .table-header {
  color: red;
}
.place-order .place-order-panel .order-detail-panel .ant-table-thead > tr > th {
  background-color: #d0d0d0;
}
.place-order-header-panel .order-header-form .order-params {
  padding: 0px 30px 0px 30px;
}
.place-order-header-panel .order-header-form .order-params .ant-col {
  margin-right: 10%;
}
.place-order-header-panel .order-header-form .order-params .ant-col:last-child {
  margin-right: 0px;
}
.place-order-header-panel .order-header-form .order-params .ant-form-item .ant-form-item-label {
  width: 27%;
  text-align: left;
}
.place-order-header-panel .order-header-form .selectClass {
  width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.order-detail-table-row td {
  vertical-align: top;
}
.order-detail {
  margin-left: 20px;
  margin-right: 20px;
}

