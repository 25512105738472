@import '../../styles/mixins';

.place-order-header-panel {
  .order-header-form {
    .order-params {
      padding: 0px 30px 0px 30px;
      .ant-col {
        margin-right: 10%; 
      }
      .ant-col:last-child {
        margin-right: 0px; 
      }
      .ant-form-item {
        .ant-form-item-label {
          width: 27%;
          text-align: left;
        }
      }
    }
    .selectClass{
      width : 280px;
      //border : 1px #12a3e4 solid;
      overflow : hidden;
      text-overflow : ellipsis;
      white-space : nowrap;
      }
  }
}
