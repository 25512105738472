@import '../../styles/mixins';

.home-menu {
  box-sizing: border-box;
  width: 100%;
  height: 35px;
  background-color: #A1A1A1;
  box-shadow: 0px 3px 5px #00000029;
  padding: 0px 26px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-bottom: 0px;
  .menu-link {
    color: #ffffff;
    padding: 0px 6px;
    &:hover, &:visited, &:active {
      // color: #f90;
      text-decoration: underline;
    }
  }
}
