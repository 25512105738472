@import '../../styles/mixins';

.head-panel {
  box-sizing: border-box;
  top: 0;
  left: 0;
  margin: 0;
  padding: 16px 24px;
  width: 100%;
  height: 70px;
  background-color: #008CD2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  .header-left {
    display: flex;
    align-items: center;
    .sysco-logo {
      width: 107px;
      height: 36px;
      padding-right: 17px;
    }
    .custom-divider {
      height: 4em;
      font-size: 10px;
      border-color: #ffffffd4;
      border-width: 2px;
      margin: 0 30px 0 20px;
    }
  }
}
