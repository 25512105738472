@import '../../styles/mixins';

.place-order {
  .place-order-panel {
    box-sizing: border-box;
    margin: 16px 24px;
    padding: 21px 24px;
    background: #ffffffcc 0% 0% no-repeat padding-box;
    box-shadow: 6px 6px 16px #00000080;
    border: 1px solid #979797;
    border-radius: 3px;
    .order-header-panel, .order-detail-panel {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 4px #00000080;
      border: 1px solid #e4e5e6;
      border-radius: 3px;
    }
    .order-header-panel {
      padding: 20px 0px 0px 0px;
      min-height: 400px;
      position: relative;
    }
    .order-detail-panel {
      margin-top: 12px;
      .table-header {
        color: red;
      }
      .ant-table-thead > tr > th {
        background-color: #d0d0d0;
      }
    }
  }
}
