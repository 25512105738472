@import './mixins';

// Here you put all global css rules.
:root {
  --green-color : #56B146; 
  --blue-color: #008CD2; 
}
// @green-color : #56B146;
// @blue-color: #008CD2;
/* MyriadPro-Regular */
@font-face {
  font-family: 'MyriadPro';
  font-style: normal;
  src: url('../fonts/MyriadPro-Regular.woff') format('woff'), /* chrome、firefox */
       url('../fonts/MyriadPro-Regular.otf') format('opentype');
}
/* MyriadPro-Bold */
@font-face {
  font-family: 'MyriadPro-Bold';
  font-style: normal;
  src: url('../fonts/MyriadPro-Bold.woff') format('woff'), /* chrome、firefox */
       url('../fonts/MyriadPro-Bold.otf') format('opentype');
}

/* MyriadPro-Semibold */
@font-face {
  font-family: 'MyriadPro-Semibold';
  font-style: normal;
  src: url('../fonts/MyriadPro-Semibold.otf') format('opentype');
}

body {
  margin: 0;
  padding: 0;
  font-family: MyriadPro, sans-serif;
  background: transparent url('../images/sysco-bg.png') 0% 0% no-repeat fixed padding-box;
  background-size: cover;
}

.text-color-primary {
  color: #008CD2;
}

.ant-btn-green {
  background: var(--green-color) 0% 0% no-repeat padding-box;
  border: 1px solid var(--green-color);
  border-radius: 3px;
  color: #ffffff;@import './mixins';

// Here you put all global css rules.
:root {
  --green-color : #56B146; 
  --blue-color: #008CD2; 
}
// @green-color : #56B146;
// @blue-color: #008CD2;
/* MyriadPro-Regular */
@font-face {
  font-family: 'MyriadPro';
  font-style: normal;
  src: url('../fonts/MyriadPro-Regular.woff') format('woff'), /* chrome、firefox */
       url('../fonts/MyriadPro-Regular.otf') format('opentype');
}
/* MyriadPro-Bold */
@font-face {
  font-family: 'MyriadPro-Bold';
  font-style: normal;
  src: url('../fonts/MyriadPro-Bold.woff') format('woff'), /* chrome、firefox */
       url('../fonts/MyriadPro-Bold.otf') format('opentype');
}

/* MyriadPro-Semibold */
@font-face {
  font-family: 'MyriadPro-Semibold';
  font-style: normal;
  src: url('../fonts/MyriadPro-Semibold.otf') format('opentype');
}

body {
  margin: 0;
  padding: 0;
  font-family: MyriadPro, sans-serif;
  background: transparent url('../images/sysco-bg.png') 0% 0% no-repeat fixed padding-box;
  background-size: cover;
}

.text-color-primary {
  color: #008CD2;
}

.ant-btn-green {
  background: var(--green-color) 0% 0% no-repeat padding-box;
  border: 1px solid var(--green-color);
  border-radius: 3px;
  color: #ffffff;
}

.ant-btn-green:hover, .ant-btn-green:focus {
  background-color: #56B146c9;
  border: 1px solid var(--green-color);
  color: #ffffff;
}

.ant-btn-green-border {
  border: 1px solid var(--green-color);
  border-radius: 3px;
  color: var(--green-color);
}

.ant-btn-green-border:hover, .ant-btn-green-border:focus {
  border: 2px solid var(--green-color);
  color: var(--green-color);
}

.ant-btn-blue-border {
  border: 1px solid var(--blue-color);
  border-radius: 3px;
  color: var(--blue-color);
}

.ant-btn-blue-border:hover, .ant-btn-blue-border:focus {
  border: 2px solid var(--blue-color);
  color: var(--blue-color);
}

.display-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.font-size-14 {
  font: 14px/21px MyriadPro, sans-serif;
}
.font-size-16 {
  font: 500 16px/18px MyriadPro, sans-serif;
}
.font-size-18 {
  font: 500 18px/23px MyriadPro, sans-serif;
}
.font-size-24 {
  font: 24px/29px MyriadPro-Bold, sans-serif;
}
.font-size-28 {
  font: 500 28px/34px MyriadPro, sans-serif;
}

.ant-form-item-label > label:not(.ant-form-item-required) {
  margin-left: 14px;
}
}

.ant-btn-green:hover, .ant-btn-green:focus {
  background-color: #56B146c9;
  border: 1px solid var(--green-color);
  color: #ffffff;
}

.ant-btn-green-border {
  border: 1px solid var(--green-color);
  border-radius: 3px;
  color: var(--green-color);
}

.ant-btn-green-border:hover, .ant-btn-green-border:focus {
  border: 2px solid var(--green-color);
  color: var(--green-color);
}

.ant-btn-blue-border {
  border: 1px solid var(--blue-color);
  border-radius: 3px;
  color: var(--blue-color);
}

.ant-btn-blue-border:hover, .ant-btn-blue-border:focus {
  border: 2px solid var(--blue-color);
  color: var(--blue-color);
}

.display-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.font-size-14 {
  font: 14px/21px MyriadPro, sans-serif;
}
.font-size-16 {
  font: 500 16px/18px MyriadPro, sans-serif;
}
.font-size-18 {
  font: 500 18px/23px MyriadPro, sans-serif;
}
.font-size-24 {
  font: 24px/29px MyriadPro-Bold, sans-serif;
}
.font-size-28 {
  font: 500 28px/34px MyriadPro, sans-serif;
}

.ant-form-item-label > label:not(.ant-form-item-required) {
  margin-left: 14px;
}